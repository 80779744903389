import React, { useState } from 'react';
import axios from 'axios';
import {
  Checkbox,
  Form,
  Input,
  Message,
  TextArea,
  Modal,
  Button,
} from 'semantic-ui-react';

export const ContactUsForm = () => {
  // State hooks.
  const [state, setState] = useState({
    submitting: false,
    success: false,
    error: '',
    // Let's not deal with reducers here - too complicated for a form - thus a
    // flat object it is.
    form_data_name: '',
    form_data_affiliation: '',
    form_data_email: '',
    form_data_interested_in_software: false,
    form_data_interested_in_consulting: false,
    form_data_interested_in_training: false,
    form_data_interested_in_other: false,
    form_data_how_did_you_hear_about_us: '',
    form_data_message: '',
  });

  const resetAndSetSuccess = () => {
    setState({
      ...state,
      submitting: false,
      success: true,
      error: '',
      form_data_name: '',
      form_data_affiliation: '',
      form_data_email: '',
      form_data_interested_in_software: false,
      form_data_interested_in_consulting: false,
      form_data_interested_in_training: false,
      form_data_interested_in_other: false,
      form_data_how_did_you_hear_about_us: '',
      form_data_message: '',
    });
  };

  const handleChange = (e, { name, value }) => {
    // Manually toggle checkboxes.
    if (name.startsWith('interested_in_')) {
      value = !state['form_data_' + name];
    }
    setState({ ...state, ['form_data_' + name]: value });
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    const formData = new FormData();
    for (const [key, value] of Object.entries(state)) {
      if (!key.startsWith('form_data_')) {
        continue;
      }
      formData.append(key.substring(10), value);
    }

    setState({ ...state, submitting: true });

    axios({
      method: 'post',
      url: 'https://usebasin.com/f/907ee5c54b62',
      data: formData,
    })
      .then(function(response) {
        resetAndSetSuccess();
      })
      .catch(function(error) {
        if (!error.status) {
          error = 'Network error. Domain cannot be reached.';
        } else {
          error = error.response.data.message;
        }

        setState({
          ...state,
          submitting: false,
          success: false,
          error: error,
        });
      });
  };

  return (
    <Form
      error={state.error.length > 0}
      success={state.success}
      onSubmit={handleOnSubmit}
    >
      <Form.Group widths="equal">
        <Form.Field
          control={Input}
          name="name"
          label="Name"
          value={state.form_data_name}
          onChange={handleChange}
          placeholder="Name"
          required
        />
        <Form.Field
          control={Input}
          label="Affiliation"
          name="affiliation"
          placeholder="Company/Institution"
          value={state.form_data_affiliation}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Field
        control={Input}
        label="Email Address"
        name="email"
        type="email"
        placeholder="name@example.com"
        value={state.form_data_email}
        onChange={handleChange}
        required
      />
      <div style={{ fontWeight: 'bold' }}>I am interested in</div>
      <Form.Group widths="equal">
        <Form.Field
          control={Checkbox}
          label="Software"
          name="interested_in_software"
          onChange={handleChange}
          checked={state.form_data_interested_in_software}
        />
        <Form.Field
          control={Checkbox}
          label="Consulting"
          name="interested_in_consulting"
          onChange={handleChange}
          checked={state.form_data_interested_in_consulting}
        />
        <Form.Field
          control={Checkbox}
          label="Training"
          name="interested_in_training"
          onChange={handleChange}
          checked={state.form_data_interested_in_training}
        />
        <Form.Field
          control={Checkbox}
          label="Other"
          name="interested_in_other"
          onChange={handleChange}
          checked={state.form_data_interested_in_other}
        />
      </Form.Group>
      <Form.Field
        control={Input}
        label="How did you hear about us?"
        name="how_did_you_hear_about_us"
        placeholder={
          'How did you become aware of Mondaic and Salvus? ' +
          'This field is optional.'
        }
        value={state.form_data_how_did_you_hear_about_us}
        onChange={handleChange}
      />
      <Form.Field
        control={TextArea}
        name="message"
        label="Message"
        required
        onChange={handleChange}
        value={state.form_data_message}
        placeholder={
          'Your message. In case you are interested in ' +
          'acquiring our software please include some details about ' +
          'your use case (problem size, domain, ...).'
        }
      />
      <Message
        success
        header="Submitted"
        content="Thank you for your message. We will get back to you shortly."
      />
      <Message error header="Failure." content={`Error: ${state.error}`} />
      <Form.Field
        control={Button}
        content={state.submitting ? 'Submitting ...' : 'Submit'}
        disabled={state.submitting}
        color="violet"
      />
    </Form>
  );
};

export const ContactUsFormModal = () => {
  const [state, setState] = useState({
    open: false,
  });

  const { open } = state;

  return (
    <div>
      <Button color="blue" onClick={() => setState({ open: true })}>
        Contact Us
      </Button>

      <Modal
        closeIcon
        dimmer="blurring"
        open={open}
        onClose={() => setState({ open: false })}
      >
        <Modal.Header>
          <p css={{ marginBottom: '0px' }}>Contact Mondaic</p>
          <p css={{ fontSize: '75%', fontWeight: '300' }}>
            You can also just write to us via{' '}
            <a href="mailto:info@mondaic.com?subject=Inquiry">
              info@mondaic.com
            </a>
            .
          </p>
        </Modal.Header>
        <Modal.Content>
          <ContactUsForm />
        </Modal.Content>
        {/* <Modal.Actions>
          <Button negative onClick={() => dispatch({ type: 'CLOSE_MODAL' })}>
            Disagree
          </Button>
          <Button positive onClick={() => dispatch({ type: 'CLOSE_MODAL' })}>
            Agree
          </Button>
        </Modal.Actions> */}
      </Modal>
    </div>
  );
};
